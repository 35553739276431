<template>
  <div>
    <b-card title="Review Details">
      <validation-observer ref="basicValidation">
        <b-form>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Hotel Name">
             <v-select
                  v-model="page.hotelID"
                  :reduce="
                    (hotel) => {
                      return hotel._id
                    }
                  "
                  label="name"
                  placeholder="Select Hotel"
                  :options="hotels"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Approve">
                <b-form-checkbox
                      checked="true"
                      v-model="page.approved"
                      name="check-button"
                      class="mb-1 mt-1"
                      switch
                      inline
                    >Review Approved</b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
       <b-row>
          <b-col cols="6">
          <b-form-group label="Communications">
                <b-form-rating  v-model="page.communication"></b-form-rating>
          </b-form-group>
        </b-col>
         <b-col cols="6">
          <b-form-group label="Cleanliness">
                <b-form-rating  v-model="page.cleanliness"></b-form-rating>
          </b-form-group>
        </b-col> 
        <b-col cols="6">
          <b-form-group label="CheckIn">
                <b-form-rating  v-model="page.checkIn"></b-form-rating>
          </b-form-group>
        </b-col> 
        <b-col cols="6">
          <b-form-group label="Location">
                <b-form-rating  v-model="page.location"></b-form-rating>
          </b-form-group>
        </b-col> 
        <b-col cols="6">
          <b-form-group label="Accuracy">
                <b-form-rating  v-model="page.accuracy"></b-form-rating>
          </b-form-group>
        </b-col> 
        <b-col cols="6">
          <b-form-group label="Value">
                <b-form-rating  v-model="page.value"></b-form-rating>
          </b-form-group>
        </b-col>
         <b-col cols="12">
              <b-form-group label="Feedback">
                <b-form-textarea
                  v-model="page.review"
                  rows="3"  
                />
              </b-form-group>
            </b-col>
       </b-row>
    </b-card>
    <b-card>
      <b-col class="align-self-center" cols="6">
              <b-button @click="createReview" variant="primary"
                >Add Review</b-button
              >
      </b-col>
    </b-card>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { required } from "@validations";
import { getUserToken } from "@/auth/utils";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BFormTextarea,
  BFormFile,
  BFormRating,
  BCardText,
} from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import axios from "axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormRating,
    BFormInput,
    BFormGroup,
    quillEditor,
    BButton,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BCardText,
  },
  data() {
    return {
      required,
      file: [],
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      uploadErrors: [],
      hotels: [],
      page: {
        hotelID: "",
        approved: true,
        cleanliness: "",
        communication: "",
        checkIn: "",
        accuracy: "",
        location: "",
        value: "",
        review: "",
      },
    };
  },
  mounted() {
      this.getHotels()
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getHotels() {
       axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/hotel/get-all`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          const hotels = response.data
            ? response.data.map((hotel, index) => {
                return hotel._id;
              })
            : [];
        console.log(hotels)
        this.hotels = response.data
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error while adding the reviews!");
        });
    },
    createReview() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/hotel/reviews/add-admin`,
          this.page,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.makeToast(
            "success",
            "Success",
            `Review has been created successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error while adding the rights!");
        });
    },
    uploadCSV() {
      const csvFormData = new FormData();
      csvFormData.append("csv-data", this.file);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/right-to-public/bulk-upload`,
          csvFormData,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.data.length) {
            this.uploadErrors = response.data;
            this.makeToast(
              "danger",
              "Error",
              "Errors found while uploading data!"
            );
          } else {
            this.makeToast("success", "Success", `Uploaded successfully!`);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>